import React, { Component } from "react";
import Layout from "./../layouts/Index";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Card,
  FormControl,
} from "react-bootstrap";
import { getProduct, editProduct } from "./../../store/actions/ProductActions";
import { getAllColors } from "./../../store/actions/ColorActions";
import { getAllSizes, addSize } from "./../../store/actions/SizeActions";
import { createSchema } from "./../../helpers/validation/product";
import { instance, setAuthToken } from "./../../helpers/instance";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Dropzone from "react-dropzone";
import LoadingOverlay from "react-loading-overlay-ts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      colors: [],

      selectedColors: [],

      categories: [],

      files: [],

      images: [],

      sizes: [],

      selectedSizes: [],

      previews: [],

      checked: false,

      charLimit: 30,
    };
  }

  componentDidMount = async () => {
    await this.props.getAllColors();

    const {
      match: { params },
    } = this.props;

    this.setState({ loading: true });

    await this.getCategories();

    await this.getAllColors();

    await this.getAllSizes();

    // console.log(params);

    const result = await this.props.getProduct(params.id);

    if (result) {
      if (result.varying_sizes === 1) {
        this.setState({ checked: true });
      }

      let images = [];

      if (result.images !== undefined || result.images !== null) {
        images = images.concat(result.images);
      }

      this.setState({ images });

      // console.log(result);

      this.getSelectedColors(result.readable_colors);

      this.getSelectedSizes(result.readable_sizes);

      this.setState({ loading: false });
    }
  };

  getCategories = async () => {
    try {
      setAuthToken(instance);

      const response = await instance.get("categories");

      if (response.data !== "") {
        // console.log(response.data);

        this.setState({ categories: response.data.categories });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getSelectedColors = (colors) => {
    const { selectedColors } = this.state;

    let existing = [];

    // console.log(colors);

    if (colors !== null) {
      colors.map((color) => {
        // console.log(color);

        let i = selectedColors.find((s) => {
          return s.value === color.id;
        });

        if (i) {
          existing.push(i);
        }

        // console.log(i);

        return color;
      });
    }

    this.setState({ colors: existing });

    // console.log(existing);
  };

  getSelectedSizes = (allsizes) => {
    // console.log(allsizes);

    const { sizes } = this.state;

    let existing = [];

    // console.log(colors);

    if (allsizes !== null) {
      allsizes.map((size) => {
        // console.log(parseInt(size));

        let i = sizes.find((s) => {
          return s.value === size.id;
        });

        if (i) {
          existing.push(i);
        }

        // console.log(i);

        return size;
      });
    }

    this.setState({ selectedSizes: existing });

    // console.log(existing);
  };

  getAllColors = async () => {
    try {
      const result = await this.props.getAllColors();

      if (result) {
        let colours = result.colors,
          selectedColors = [];

        for (let i = 0; i < colours.length; i++) {
          selectedColors.push({
            value: colours[i].id,

            label: colours[i].label,
          });
        }

        this.setState({ selectedColors });

        // console.log(selectedColors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getAllSizes = async () => {
    try {
      const result = await this.props.getAllSizes();

      if (result) {
        let sizes = result.sizes,
          selectedSizes = [];

        for (let i = 0; i < sizes.length; i++) {
          selectedSizes.push({
            value: sizes[i].id,

            label: sizes[i].label,
          });
        }

        this.setState({ sizes: selectedSizes });

        // console.log(selectedSizes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  update = async (data) => {
    try {
      const { files, images, checked } = this.state;

      let imgs = [];

      const {
        products: { product },
        history,
      } = this.props;

      if (images !== undefined || images !== null) {
        imgs = imgs.concat(images);
      }

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let formData = new FormData();

          formData.append("file", files[i]);

          const response = await instance.post("upload", formData);

          if (response.data !== "") {
            imgs.push(response.data.file);
          }
        }

        data.images = imgs;
      } else {
        data.images = imgs;
      }

      let selectedColors = data.colors,
        colors = [];

      if (selectedColors.length > 0) {
        selectedColors.forEach((c) => {
          colors.push(c.value.toString());
        });

        data.colors = colors;
      } else {
        this.setState({ loading: false });

        return toast.error("Colors Is Required !", {
          position: "top-right",
        });
      }

      let selectedSizes = data.sizes,
        sizes = [];

      if (selectedSizes.length > 0) {
        selectedSizes.forEach(async (c) => {
          if (c.label === c.value) {
            const response = await this.props.addSize(c);

            sizes.push(response.id.toString());
          } else {
            sizes.push(c.value.toString());
          }
        });

        data.sizes = sizes;
      } else {
        this.setState({ loading: false });

        return toast.error("Sizes is required !", {
          position: "top-right",
        });
      }

      if (checked === true) {
        data.varying_sizes = 1;
      } else {
        data.varying_sizes = 0;
      }

      // console.log(data);

      this.setState({ loading: true });

      const result = await this.props.editProduct(product.id, data);

      if (result) {
        this.setState({ loading: false });

        toast.success("Product Edited Successfully !", {
          position: "top-right",
        });

        return history.push(`/admin/products/${product.id}`);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-right",
        });
      }
    }
  };

  deleteS3Image = async (url) => {
    try {
      // console.log(url);

      const { images } = this.state;

      let photos = images.filter((img) => {
        return img !== url;
      });

      // console.log(photos);

      this.setState({ loading: true });

      setAuthToken(instance);

      // console.log(photos);

      const response = await instance.post("delete", { url });

      if (response.data !== "") {
        this.setState({ images: photos, loading: false });

        // console.log(response.data);
      }
    } catch (error) {
      console.log(error);

      this.setState({ loading: false });
    }
  };

  deleteImage = (file) => {
    const { previews } = this.state;

    let images = previews.filter((img) => {
      return img !== file;
    });

    this.setState({ previews: images });
  };

  previewFiles = (files) => {
    let data = [];

    for (let i = 0; i < files.length; i++) {
      // console.log(files[i]);

      let uri = URL.createObjectURL(files[i]);

      // console.log(uri);

      data.push(uri);

      //   this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    }

    this.setState({ previews: data });
  };

  removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  render() {
    const {
      products: { product },
    } = this.props;

    // console.log(product);

    const {
      loading,
      selectedColors,
      colors,
      categories,
      sizes,
      selectedSizes,
      previews,
      images,
    } = this.state;

    return (
      <LoadingOverlay active={loading} spinner text="Wait ...">
        <Layout>
          <Col sm="12" style={{ minHeight: "80vh", padding: "1.5em 0" }}>
            <Container>
              <Row>
                <Col sm="12">
                  <h4>Edit Product</h4>
                </Col>

                <Col sm="12">
                  <Card>
                    <Card.Body>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: product.name !== undefined ? product.name : "",
                          description:
                            product.description !== undefined
                              ? product.description
                              : "",
                          status:
                            product.status !== undefined ? product.status : 0,
                          colors: colors.length > 0 ? colors : [],
                          category_id:
                            product.category_id !== undefined
                              ? product.category_id
                              : "",
                          sizes: selectedSizes.length > 0 ? selectedSizes : [],
                        }}
                        validationSchema={createSchema}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          this.update(values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          setFieldTouched,
                        }) => (
                          <Form
                            noValidate
                            onSubmit={(e) => {
                              e.preventDefault();

                              // console.log('Form Submitted !');

                              handleSubmit();
                            }}
                          >
                            <Row>
                              <Col sm="6">
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Name</Form.Label>

                                  <Form.Control
                                    type="text"
                                    placeholder="Product Name"
                                    name="name"
                                    isValid={touched.name && !errors.name}
                                    isInvalid={errors.name && touched.name}
                                    value={values.name}
                                    onChange={(e) => {
                                      let txt = e.target.value;

                                      // if(txt.length <= charLimit) {

                                      //     setFieldValue('name', txt);

                                      // }

                                      setFieldValue("name", txt);
                                    }}
                                  />

                                  {errors.name && touched.name && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.name}
                                    </Form.Control.Feedback>
                                  )}

                                  {/* <span style={{ paddingTop: '5px', fontSize: '12px' }}>{charLimit - values.name.length} characters remaining.</span> */}
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  controlId="category_id"
                                >
                                  <Form.Label>Category</Form.Label>

                                  <Form.Control
                                    as="select"
                                    isValid={
                                      touched.category_id && !errors.category_id
                                    }
                                    defaultValue={values.category_id}
                                    isInvalid={
                                      errors.category_id && touched.category_id
                                    }
                                    name="category_id"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value="">Choose ...</option>

                                    {categories.map((category, i) => (
                                      <option
                                        key={i}
                                        value={category.id}
                                        selected={
                                          category.id === values.category_id
                                            ? true
                                            : false
                                        }
                                      >
                                        {category.name}
                                      </option>
                                    ))}
                                  </Form.Control>

                                  {errors.category_id &&
                                    touched.category_id && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.status}
                                      </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Description</Form.Label>

                              {/* <FormControl as="textarea" aria-label="With textarea" name="description" isValid={touched.description && !errors.description} isInvalid={errors.description && touched.description} value={values.description} onChange={handleChange} /> */}

                              <ReactQuill
                                theme="snow"
                                value={values.description}
                                onChange={(data) =>
                                  setFieldValue("description", data)
                                }
                              />

                              {errors.description && touched.description && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.description}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>

                            {/* <Form.Group controlId="source">

                                                        <Form.Label>Source</Form.Label>

                                                        <Form.Control type="text" placeholder="Product Source" name="source" isValid={touched.source && !errors.source} isInvalid={errors.source && touched.source} value={values.source} onChange={handleChange} />

                                                        {errors.source && touched.source && <Form.Control.Feedback type="invalid">{errors.source}</Form.Control.Feedback>}

                                                    </Form.Group> */}

                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Photo(s)</Form.Label>

                              {/* <Form.Control type="file" name="file" accept='image/*' multiple onChange={async (e) => { 
                                                            
                                                            // console.log(e.target.files);

                                                            this.previewFiles(e.target.files);

                                                            this.setState({ files: e.target.files });
                                                            
                                                        }} /> */}

                              <Dropzone
                                accept="image/*"
                                onDrop={(acceptedFiles) => {
                                  // console.log(acceptedFiles);

                                  this.previewFiles(acceptedFiles);

                                  this.setState({ files: acceptedFiles });
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        flex: 1,
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "20px",
                                        borderWidth: 2,
                                        borderRadius: 2,
                                        borderColor: "#eee",
                                        borderStyle: "dashed",
                                        backgroundColor: "#fafafa",
                                        color: "#bdbdbd",
                                        outline: "none",
                                        transition: "border .24s ease-in-out",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <input {...getInputProps()} />

                                      <p
                                        style={{
                                          textAlign: "center",
                                          marginTop: "1em",
                                        }}
                                      >
                                        Drag 'n' drop some files here, or click
                                        to select files
                                      </p>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  margin: "15px 0",
                                }}
                              >
                                {images !== undefined &&
                                  images.length > 0 &&
                                  images.map((image, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        backgroundImage: `url("${image}")`,
                                        backgroundSize: "cover",
                                        margin: "0 6px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.deleteS3Image(image);
                                      }}
                                    >
                                      <Button
                                        variant="danger"
                                        type="button"
                                        size="sm"
                                        style={{
                                          postition: "absolute",
                                          right: "0",
                                          margin: "5px",
                                        }}
                                      >
                                        x
                                      </Button>
                                    </div>
                                  ))}

                                {previews.length > 0 &&
                                  previews.map((image, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        backgroundImage: `url("${image}")`,
                                        backgroundSize: "cover",
                                        margin: "0 6px",
                                      }}
                                      onClick={() => {
                                        this.deleteImage(image);
                                      }}
                                    >
                                      <Button
                                        variant="danger"
                                        type="button"
                                        size="sm"
                                        style={{
                                          postition: "absolute",
                                          right: "0",
                                          margin: "5px",
                                        }}
                                      >
                                        x
                                      </Button>
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                            <Row>
                              <Col sm="6">
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  controlId="colors"
                                >
                                  <Form.Label>Colors</Form.Label>

                                  <Select
                                    defaultValue={values.colors}
                                    value={values.colors}
                                    options={selectedColors}
                                    isMulti
                                    onChange={(value) => {
                                      setFieldValue("colors", value);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched("colors", true);
                                    }}
                                    touched={touched.colors}
                                  />

                                  {/* {errors.colors && touched.colors && <Form.Control.Feedback type="invalid">{errors.colors}</Form.Control.Feedback>} */}
                                </Form.Group>
                              </Col>
                              <Col sm="6">
                                <Form.Group
                                  style={{ marginTop: "1em" }}
                                  controlId="sizes"
                                >
                                  <Form.Label>Sizes</Form.Label>

                                  <CreatableSelect
                                    defaultValue={values.sizes}
                                    value={values.sizes}
                                    options={sizes}
                                    isMulti
                                    onChange={(value) => {
                                      setFieldValue("sizes", value);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched("sizes", true);
                                    }}
                                    touched={touched.sizes}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="status"
                            >
                              <Form.Label>Status</Form.Label>

                              <Form.Control
                                as="select"
                                isValid={touched.status && !errors.status}
                                defaultValue={values.status}
                                isInvalid={errors.status && touched.status}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option
                                  value={1}
                                  selected={1 === values.status ? true : false}
                                >
                                  Activated
                                </option>

                                <option
                                  value={0}
                                  selected={0 === values.status ? true : false}
                                >
                                  Deactivated
                                </option>
                              </Form.Control>

                              {errors.status && touched.status && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.status}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>

                            <Form.Group
                              style={{ marginTop: "1em" }}
                              controlId="varying_sizes"
                            >
                              <Form.Check
                                checked={this.state.checked}
                                onChange={(e) => {
                                  this.setState({ checked: e.target.checked });
                                }}
                                type="checkbox"
                                label="Varying Sizes"
                              />
                            </Form.Group>

                            <Button
                              style={{ marginTop: "1em" }}
                              variant="primary"
                              type="submit"
                              disabled={loading}
                            >
                              Edit Product
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Layout>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.products,
  };
};

export default connect(mapStateToProps, {
  getProduct,
  editProduct,
  getAllColors,
  getAllSizes,
  addSize,
})(withRouter(Edit));
